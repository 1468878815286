import React, { useState, useEffect } from "react";
import { sanityClient } from "./lib/Sanity";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      try {
        const result = await sanityClient.fetch(
          `
          *[_type == "termsAndConditions" && slug.current == $slug][0] {
            title,
            version,
            effectiveDate,
            content
          }
        `,
          { slug }
        );
        setData(result);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching Terms and Conditions:", err);
        setError(err);
        setIsLoading(false);
      }
    };

    fetchTermsAndConditions();
  }, [slug]);

  const handleLinkClick = (event) => {
    // Check for nested elements inside the anchor tag
    const anchorElement = event.target.closest("a");
    debugger;
    if (!anchorElement) return;

    const href = anchorElement.getAttribute("href");

    console.log("Clicked href:", href); // Debug log

    if (href && href.startsWith("#")) {
      event.preventDefault();
      const targetElement = document.querySelector(href);

      if (targetElement) {
        // Smooth scroll to element
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        // Update URL without full navigation
        navigate(`${location.pathname}${href}`, { replace: true });
      }
    }
  };

  if (isLoading)
    return <div className="text-gray-700 text-center">Loading...</div>;

  if (error)
    return (
      <div className="text-red-500 text-center">
        Error loading Terms and Conditions
      </div>
    );

  if (!data) return null;

  return (
    <div className="bg-gray-100 min-h-screen text-gray-800 p-8">
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <div
          className="prose prose-sm p-10"
          dangerouslySetInnerHTML={{
            __html: data.content?.code,
          }}
          onClick={handleLinkClick}
        />
      </div>
    </div>
  );
};

export default TermsAndConditions;
