export const LoadingState = () => (
  <div className="flex items-center justify-center min-h-[70vh] bg-gray-100">
    <div className="p-8 bg-white shadow-2xl rounded-2xl">
      <div className="flex flex-col items-center">
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
        <h2 className="mt-4 text-xl font-semibold text-gray-700">Loading...</h2>
        <p className="mt-2 text-gray-500">
          Please wait while we fetch the data.
        </p>
      </div>
    </div>
  </div>
);

export const ErrorState = ({ error }) => (
  <div className="flex items-center justify-center min-h-[70vh] bg-gray-100">
    <div className="p-8 bg-white shadow-2xl rounded-2xl">
      <div className="flex flex-col items-center">
        <svg
          className="w-16 h-16 text-red-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <h2 className="mt-4 text-xl font-semibold text-gray-700">
          Error Occurred
        </h2>
        <p className="mt-2 text-center text-red-500">{error}</p>
      </div>
    </div>
  </div>
);
export const NotFoundState = () => (
  <div className="flex items-center justify-center min-h-[70vh] bg-gray-100">
    <div className="p-8 bg-white shadow-2xl rounded-2xl">
      <div className="flex flex-col items-center">
        <svg
          className="w-16 h-16 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
          ></path>
        </svg>
        <h2 className="mt-4 text-xl font-semibold text-gray-700">
          User Not Found
        </h2>
        <p className="mt-2 text-gray-500">
          We couldn't find the user you're looking for.
        </p>
      </div>
    </div>
  </div>
);
