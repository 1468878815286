// export const BASE_URL = "https://localhost:7155/api";
// export const FLASK_URL = "http://127.0.0.1:5000/api";
export const BASE_FUN_URL = "https://windows-yasmin-fn.azurewebsites.net";
// export const BASE_FUN_URL = "http://localhost:7034";
export const StorageUrl =
  "https://gddatastorage.blob.core.windows.net/yasmin-storage";
export const BASE_URL =
  "https://echo-api-cga6b2hjhbfqdad2.westeurope-01.azurewebsites.net/api";
export const containerName = "ai-files";

export const Client_id =
  "875838615965-06i02kptajqq5b0evqa0p8g75q5sbrut.apps.googleusercontent.com";
export const convertSecondsToMinutes = (totalSeconds) => {
  if (totalSeconds < 0) {
    console.log("Total seconds cannot be negative.");
    return;
  }

  const minutes = Math.floor(totalSeconds / 60);

  // Format the minutes with commas for thousands
  return minutes.toLocaleString();
};
