import React, { useState } from "react";

const LoadingButton = ({ children, onClick, className, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    if (onClick) {
      await onClick();
    }
  };

  return (
    <button
      className={`btn-process font-montserrat text-2xl 
                  border-none outline-none flex items-center justify-center gap-2 px-12 py-4 rounded-2xl 
                  text-white cursor-pointer ${className} ${
        disabled ? "disabled:opacity-70" : ""
      }`}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
      <span className={`btn-ring ${disabled ? "block" : "hidden"}`}></span>
    </button>
  );
};

export default LoadingButton;
