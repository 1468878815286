import React, { useContext, useState } from "react";
import axios from "axios";
import AppContext from "../AppContext";
import { BASE_URL } from "../constants";

const CloseButton = ({ onClose }) => (
  <button
    onClick={onClose}
    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
    aria-label="Close"
  >
    <svg
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
);

const FormField = ({
  label,
  id,
  value,
  onChange,
  type = "text",
  required = true,
  rows,
}) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {type === "textarea" ? (
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        rows={rows}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        required={required}
      />
    ) : (
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        required={required}
      />
    )}
  </div>
);

const SubmitButton = ({ isSubmitting }) => (
  <button
    type="submit"
    disabled={isSubmitting}
    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
  >
    {isSubmitting ? "Submitting..." : "Submit"}
  </button>
);

const BugReportModal = ({ onClose }) => {
  const [section, setSection] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const authCtx = useContext(AppContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError("");

    try {
      await axios.post(`${BASE_URL}/BugReport`, {
        userId: authCtx.profile.id,
        section,
        description,
      });
      setSubmitSuccess(true);
      setTimeout(onClose, 2000);
    } catch (error) {
      setSubmitError(
        error.response?.data?.message ||
          "Failed to submit bug report. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-xl p-8 m-4 max-w-xl w-full">
        <CloseButton onClose={onClose} />

        <h2 className="text-2xl font-bold mb-4">Report a Bug</h2>

        {submitSuccess ? (
          <div className="text-green-600 font-semibold">
            Thanks for reporting the bug. We'll look into it!
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormField
              label="Section"
              id="section"
              value={section}
              onChange={(e) => setSection(e.target.value)}
            />
            <FormField
              label="Describe the bug"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="textarea"
              rows="4"
            />
            {submitError && (
              <div className="text-red-600 mb-4" role="alert">
                {submitError}
              </div>
            )}
            <div className="flex justify-end">
              <SubmitButton isSubmitting={isSubmitting} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default BugReportModal;
