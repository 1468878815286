import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BlobServiceClient } from "@azure/storage-blob";
import { useNavigate } from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { BASE_FUN_URL, BASE_URL } from "../constants";

export const useAudioFile = () => {
  const [file, setFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("audio/")) {
      setFile(file);
      setMediaUrl(URL.createObjectURL(file));
    } else {
      toast.error("Please upload an audio file only.");
    }
  }, []);

  return { file, setFile, mediaUrl, setMediaUrl, onDrop };
};

export const useAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const recorderRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorderRef.current = recorder;
      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        setRecordedAudio(blob);
        const file = new File([blob], "recorded_audio.webm", {
          type: "audio/webm",
        });
        return { file, url: URL.createObjectURL(blob) };
      };

      recorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (recorderRef.current && isRecording) {
      recorderRef.current.stop();
      setIsRecording(false);
      setMediaRecorder(null);
    }
  };

  return { isRecording, recordedAudio, startRecording, stopRecording };
};

export const useAudioUpload = (authCtx) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleUpload = async (file, duration, setCurrentStep) => {
    try {
      if (!file) {
        toast.error("Please select a file to upload.");
        return;
      }
      setIsLoading(true);
      const fileExtension = file.name.split(".").pop();

      const sasResponse = await axios.post(`${BASE_URL}/Lookup/get_sas_token`, {
        extension: fileExtension,
        duration: parseInt(duration),
        userEmail: authCtx.profile.email,
      });

      const { sasToken, blobUrl, blobName, documentId } = sasResponse.data;

      const baseUrl = blobUrl.split(`/${blobName}`)[0];
      const fullBlobUrl = `${baseUrl}?${sasToken}`;

      const blobServiceClient = new BlobServiceClient(fullBlobUrl);
      const containerClient = blobServiceClient.getContainerClient("");
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      await axios.put(blockBlobClient.url, file, {
        headers: {
          "Content-Type": "application/octet-stream",
          "x-ms-blob-type": "BlockBlob",
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total / (1024 * 1024);
          const uploaded = progressEvent.loaded / (1024 * 1024);
          setUploadProgress({ total, uploaded });
        },
      });
      setCurrentStep(2);
      setFileName(`${documentId}.${fileExtension}`);
      return documentId;
    } catch (error) {
      console.error("Error uploading media:", error);
      toast.error("Failed to upload the file. Please try again.");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, uploadProgress, fileName, setFileName, handleUpload };
};

export const useTranscription = (authCtx) => {
  const [transcription, setTranscription] = useState("");
  const [status, setStatus] = useState("");

  const triggerTranscription = (id) => {
    axios.get(`${BASE_FUN_URL}/api/transcribe/${authCtx.profile.email}/${id}`);
  };

  const fetchLatestFileLog = async (mode) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Lookup/file-log/latest/${authCtx.profile.email}?fileType=${mode}`
      );

      if (response.data) {
        const { id, fileUrl, transcription, status } = response.data;
        return { id, fileUrl, transcription, status };
      }
    } catch (error) {
      console.error("Error fetching the latest file log:", error);
    }
  };

  const updateFileLog = (updatedDocument, setIsLoading, setCurrentStep) => {
    if (updatedDocument.status === "Completed") {
      if (updatedDocument.transcription) {
        setTranscription(updatedDocument.transcription);
      }
      setIsLoading(false);
      setCurrentStep(2);
      setStatus("Completed");
    } else {
      setStatus("awaiting Processing");
    }
  };

  return {
    transcription,
    setTranscription,
    status,
    setStatus,
    triggerTranscription,
    fetchLatestFileLog,
    updateFileLog,
  };
};

export const useAudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const mediaRef = useRef(null);

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      const setMediaData = () => {
        setDuration(media.duration);
        setCurrentTime(media.currentTime);
      };

      const setMediaTime = () => setCurrentTime(media.currentTime);

      media.addEventListener("loadeddata", setMediaData);
      media.addEventListener("timeupdate", setMediaTime);

      return () => {
        media.removeEventListener("loadeddata", setMediaData);
        media.removeEventListener("timeupdate", setMediaTime);
      };
    }
  }, []);

  const togglePlayPause = () => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (e) => {
    const media = mediaRef.current;
    if (media) {
      const newTime = (e.target.value / 100) * media.duration;
      media.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return {
    isPlaying,
    duration,
    currentTime,
    mediaRef,
    togglePlayPause,
    handleProgressChange,
    formatTime,
  };
};

export const useSummary = () => {
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSummarize = async (transcription, setCurrentStep) => {
    if (!transcription) return;
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/Lookup/summarize`, {
        text: transcription,
      });
      setSummary(response.data.summary);
      setCurrentStep(3);
    } catch (error) {
      console.error("Error summarizing text:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { summary, setSummary, isLoading, handleSummarize };
};
