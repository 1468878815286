import { useState } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import BugReportModal from "./BugReportModal";
import { CircleHelp } from "lucide-react";

export function Layout({ children, showFooter = true }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setIsMenuOpen(false); // Close the menu when opening the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="fixed z-50 lg:right-5 right-12 bottom-5">
        <CircleHelp
          onClick={toggleMenu}
          className="h-7 w-7 text-blue-600 cursor-pointer hover:text-blue-800 transition-colors"
          aria-label="Help Menu"
        />
        {isMenuOpen && (
          <div className="absolute z-50 w-36 right-0 -top-16 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
            <button
              onClick={openModal}
              className="block px-4 py-2 text-blue-600 hover:bg-gray-100 w-full text-left"
            >
              Report a Bug
            </button>
          </div>
        )}
      </div>

      {isModalOpen && <BugReportModal onClose={closeModal} />}
      <main className="flex-auto pt-24">
        {/* Increased top padding */}
        {children}
      </main>
      {showFooter && <Footer />}
    </div>
  );
}
