import React from "react";
import { CheckIcon } from "lucide-react";

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { number: 1, name: "Upload" },
    { number: 2, name: "Transcribe" },
    { number: 3, name: "Summarize" },
  ];

  return (
    <div className="hidden lg:block absolute left-20 top-10 h-full">
      {/* Vertical line - now stops at last step */}
      <div
        className="absolute left-3 top-[60px] w-0.5 bg-gray-200"
        style={{ height: "calc(66.666% - 60px)" }}
      />

      {steps.map((step, index) => {
        const isCompleted = currentStep > step.number;
        const isActive = currentStep === step.number;

        return (
          <div
            key={step.number}
            className="absolute flex items-center"
            style={{
              top:
                index === 0
                  ? "40px"
                  : index === 1
                  ? "33.333%"
                  : index === 2
                  ? "66.666%"
                  : "0",
              transform:
                index === 1 || index === 2 ? "translateY(-50%)" : "none",
            }}
          >
            <div
              className={`
                w-6 h-6 rounded-full flex items-center justify-center
                transition-colors duration-300 z-10
                ${
                  isCompleted
                    ? "bg-green-500 text-white"
                    : isActive
                    ? "bg-blue-500 text-white ring-4 ring-blue-100"
                    : "bg-gray-200 text-gray-600"
                }
              `}
            >
              {isCompleted ? (
                <CheckIcon size={14} />
              ) : (
                <span className="text-xs font-semibold">{step.number}</span>
              )}
            </div>

            <div
              className={`
                ml-3 font-medium whitespace-nowrap transition-colors duration-300
                ${
                  isActive
                    ? "text-blue-500 font-semibold"
                    : isCompleted
                    ? "text-gray-900"
                    : "text-gray-400"
                }
              `}
            >
              {step.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepIndicator;
