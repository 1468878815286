import GoogleLogin from "react-google-login";
import { BASE_URL, Client_id } from "../constants";
import AppContext from "../AppContext";
import { useContext, useState } from "react";
import EmailForm from "./EmailForm";
import { toast } from "react-toastify";
import LoadingButton from "./LoadingButton";
import Spinner from "./Spinner";
import { Plus } from "lucide-react";
import axios from "axios";

export default function Login({ setShowLoginModal, onLoginSuccess }) {
  const authCtx = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [signup, setSignup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const handleGoogleLogin = async (response) => {
    try {
      setLoading(true);
      const { profileObj } = response;
      // Handle Google login response
      const payload = {
        name: profileObj.name,
        email: profileObj.email,
      };

      // Send POST request to the API
      const apiResponse = await fetch(`${BASE_URL}/Auth/SingleSignOn`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Parse the JSON response
      const result = await apiResponse.json();

      // Check if the API call was successful
      if (apiResponse.ok) {
        authCtx.login(result); // Assuming result contains the necessary data for login
        setShowLoginModal(false);
        onLoginSuccess();
      } else {
        // Handle errors
        toast.error(result.message || "Login failed");
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleGoogleLoginFail = (response) => {
    toast.error("Login Failed");
  };
  const handleModalClick = (event) => {
    event.stopPropagation();
  };
  const handleLoginClick = async (event) => {
    // Validate email and password
    if (!email || !password) {
      toast.error("Please enter both email and password");
      return;
    }

    try {
      setLoading(true);
      const payload = {
        email,
        password,
      };

      const apiResponse = await fetch(`${BASE_URL}/Auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await apiResponse.json();

      if (apiResponse.ok) {
        authCtx.login(result);
        setShowLoginModal(false);
        onLoginSuccess();
      } else {
        toast.error(result.message || "Login failed");
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Spinner />; // Show spinner while loading
  }
  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    if (!forgotPasswordEmail) {
      toast.error("Please enter your email address");
      return;
    }
    try {
      setLoading(true);

      const response = await axios.get(`${BASE_URL}/Auth/ResetPassword`, {
        params: {
          email: forgotPasswordEmail, // Include email as a query parameter
        },
      });

      if (response.status === 200) {
        toast.success(
          "An email has been sent with password reset instructions"
        );
        setShowForgotPassword(false);
      } else {
        toast.error(
          response.data.message || "Failed to process forgot password request"
        );
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while processing your request"
      );
      console.error("Forgot password error:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      onClick={() => setShowLoginModal(false)}
      className="fixed inset-0 top-0 h-screen flex justify-center items-center z-50 bg-black bg-opacity-50 p-4"
    >
      <div
        onClick={handleModalClick}
        className="w-full max-w-md bg-white rounded-lg shadow relative "
      >
        <button
          onClick={() => setShowLoginModal(false)}
          className="absolute right-3 top-3"
        >
          <Plus className="rotate-45 h-6 w-6 text-black" />
        </button>
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="pb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {showForgotPassword
                ? "Forgot Password"
                : "Sign in to your account"}
            </h2>
          </div>
          {showForgotPassword ? (
            <form onSubmit={handleForgotPasswordSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="forgotPasswordEmail"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="forgotPasswordEmail"
                    name="email"
                    type="email"
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    required
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <LoadingButton
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Reset Password
                </LoadingButton>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(false)}
                  className="text-sm text-indigo-600 hover:text-indigo-500"
                >
                  Back to Login
                </button>
              </div>
            </form>
          ) : (
            <form action="#" method="POST" className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="email"
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    autoComplete="current-password"
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm leading-6">
                  <button
                    type="button"
                    onClick={handleForgotPasswordClick}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </button>
                </div>
              </div>

              <div>
                <LoadingButton
                  type="submit"
                  onClick={handleLoginClick}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Login
                </LoadingButton>
              </div>
            </form>
          )}

          <div>
            <div className="relative mt-10">
              <div
                aria-hidden="true"
                className="absolute inset-0 flex items-center"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="space-y-4 sm:space-y-0 sm:flex sm:gap-4">
              <GoogleLogin
                clientId={Client_id}
                onSuccess={handleGoogleLogin}
                onFailure={handleGoogleLoginFail}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    className="w-full mt-2 flex items-center justify-center py-2.5 px-4 border border-gray-300 rounded-lg hover:bg-gray-50 focus:ring-4 focus:ring-gray-100 focus:outline-none"
                  >
                    <svg
                      className="mr-2 h-5 w-5"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_13183_10121)">
                        <path
                          d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
                          fill="#3F83F8"
                        />
                        <path
                          d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
                          fill="#34A853"
                        />
                        <path
                          d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
                          fill="#FBBC04"
                        />
                        <path
                          d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
                          fill="#EA4335"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13183_10121">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Google
                  </button>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
