import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { AssemblyAI } from "assemblyai";
import { Play, Pause, Upload, Mic, StopCircle } from "lucide-react";

const client = new AssemblyAI({
  apiKey: "4ce125c3fb5d4315b506ed197799369b",
});

export default function AudioToTextComponent() {
  const API_KEY = "4ce125c3fb5d4315b506ed197799369b";
  const [file, setFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const mediaRef = useRef(null);
  const progressRef = useRef(null);
  const recorderRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);
    setMediaUrl(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "audio/,video/",
  });

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      const setMediaData = () => {
        setDuration(media.duration);
        setCurrentTime(media.currentTime);
      };

      const setMediaTime = () => setCurrentTime(media.currentTime);

      media.addEventListener("loadeddata", setMediaData);
      media.addEventListener("timeupdate", setMediaTime);

      return () => {
        media.removeEventListener("loadeddata", setMediaData);
        media.removeEventListener("timeupdate", setMediaTime);
      };
    }
  }, [mediaUrl]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      recorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      recorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        setRecordedAudio(blob);
        setFile(
          new File([blob], "recorded_audio.webm", { type: "audio/webm" })
        );
        setMediaUrl(URL.createObjectURL(blob));
      };

      recorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (recorderRef.current && isRecording) {
      recorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleTranscribe = async () => {
    if (!file) return;
    setIsLoading(true);

    try {
      // Upload file to AssemblyAI
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axios.post(
        "https://speach-to-text-api.vercel.app/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(uploadResponse, "res");
      const uploadUrl = uploadResponse.data.url;

      // Initiate transcription
      const transcriptionResponse = await axios.post(
        "https://api.assemblyai.com/v2/transcript",
        {
          audio_url: uploadUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      const transcriptionId = transcriptionResponse.data.id;

      // Poll for transcription results
      let result;
      while (true) {
        result = await axios.get(
          `https://api.assemblyai.com/v2/transcript/${transcriptionId}`,
          {
            headers: { Authorization: `Bearer ${API_KEY}` },
          }
        );
        if (result.data.status === "completed") {
          setTranscription(result.data.text);
          break;
        } else if (result.data.status === "error") {
          throw new Error(`Transcription failed: ${result.data.error}`);
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    } catch (error) {
      console.error("Error transcribing media:", error);
      // setError(error.message || "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSummarize = async () => {
    if (!transcription) return;
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://speach-to-text-api.vercel.app/api/summarize",
        {
          text: transcription,
        }
      );
      setSummary(response.data.summary);
    } catch (error) {
      console.error("Error summarizing text:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePlayPause = () => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleProgressChange = (e) => {
    const media = mediaRef.current;
    if (media) {
      const newTime = (e.target.value / 100) * media.duration;
      media.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  return (
    <div className="bg-white min-h-screen pt-8">
      <div className="max-w-6xl mx-auto py-8">
        <div className="grid grid-cols-2 gap-12">
          <div>
            <h2 className="text-6xl font-bold text-gray-800 mb-4">
              AUDIO/VIDEO TO TEXT
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Transcribe audio or video to text automatically, using AI. Over
              +120 languages supported
            </p>

            {!file && (
              <>
                <div
                  {...getRootProps()}
                  className="bg-green-400 text-white px-8 py-4 rounded-full text-xl font-semibold hover:bg-green-500 transition duration-300 cursor-pointer flex items-center justify-center mb-4"
                >
                  <input {...getInputProps()} />
                  <Upload className="mr-2" />
                  Upload Audio/Video
                </div>
                <button
                  onClick={isRecording ? stopRecording : startRecording}
                  className={`${
                    isRecording
                      ? "bg-red-500 hover:bg-red-600"
                      : "bg-blue-500 hover:bg-blue-600"
                  } text-white px-8 py-4 rounded-full text-xl font-semibold transition duration-300 flex items-center justify-center`}
                >
                  {isRecording ? (
                    <>
                      <StopCircle className="mr-2" />
                      Stop Recording
                    </>
                  ) : (
                    <>
                      <Mic className="mr-2" />
                      Record Audio
                    </>
                  )}
                </button>
              </>
            )}

            {file && !transcription && (
              <button
                onClick={handleTranscribe}
                className="bg-green-400 text-white px-8 py-4 rounded-full text-xl font-semibold hover:bg-green-500 transition duration-300"
                disabled={isLoading}
              >
                {isLoading ? "Transcribing..." : "Transcribe Media"}
              </button>
            )}
            {transcription && (
              <div
                {...getRootProps()}
                className="bg-green-400 text-white px-8 py-4 rounded-full text-xl font-semibold hover:bg-green-500 transition duration-300 cursor-pointer flex items-center justify-center"
              >
                <input {...getInputProps()} />
                <Upload className="mr-2" />
                Upload Another
              </div>
            )}
          </div>

          <div className="bg-blue-100 rounded-3xl p-8">
            {!file ? (
              <div className="flex items-center justify-center h-full">
                <p className="text-xl text-gray-600">
                  Upload an audio or video file to begin
                </p>
              </div>
            ) : (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={48} className="text-blue-500 mr-4" />
                    ) : (
                      <Play size={48} className="text-blue-500 mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                      ref={progressRef}
                    />
                  </div>
                </div>
                <div className="flex justify-between text-sm text-gray-600 mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <div className="bg-white rounded-2xl p-6 shadow-sm">
                  <p className="text-2xl text-gray-800 max-h-56 overflow-y-auto">
                    {transcription || "Transcription will appear here..."}
                  </p>
                </div>
                <audio ref={mediaRef} src={mediaUrl} className="hidden" />
              </>
            )}
          </div>
        </div>

        {transcription && (
          <div className="mt-12">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Summarize</h3>
            <button
              onClick={handleSummarize}
              className="bg-blue-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300 mb-4"
              disabled={isLoading}
            >
              {isLoading ? "Summarizing..." : "Summarize"}
            </button>
            {summary && (
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <p className="text-xl text-gray-800">{summary}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
