import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "lucide-react";
import { BackgroundImage } from "../BackgroundImage";
import { Container } from "../Container";
import Signup from "../SignUp";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const handleClick = (e) => {
  e.preventDefault();
  const element = document.getElementById("contact");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
export default function Pricing({ data }) {
  const [frequency, setFrequency] = useState(frequencies[1]);
  const [showSignupModal, setShowSignupModal] = useState(false);

  return (
    <div id="pricing" className="bg-white relative py-24 sm:py-32">
      <BackgroundImage position="right" className="" />
      <Container className="relative">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {data.heading}
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            {data.subheading}
          </p>
          <div className="mt-16 flex justify-center">
            <fieldset aria-label="Payment frequency">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                {frequencies.map((option) => (
                  <Radio
                    key={option.value}
                    value={option}
                    className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-indigo-600 data-[checked]:text-white"
                  >
                    {option.label}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {data.subscriptionTier.map((tier, index) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "ring-2 ring-indigo-600"
                    : "ring-1 ring-gray-200",
                  "rounded-3xl p-4 xl:p-6 shadow-xl bg-white"
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                {tier?.price ? (
                  <p className="mt-6 flex items-baseline gap-x-1">
                    {frequency.value === "monthly" && tier.strikedPrice && (
                      <span className="text-2xl font-medium line-through text-gray-500 mr-2">
                        {tier.strikedPrice[frequency.value]}
                      </span>
                    )}
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {tier.price[frequency.value]}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      {frequency.priceSuffix}
                    </span>
                  </p>
                ) : (
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold h-10 tracking-tight text-gray-900">
                      {" "}
                    </span>
                  </p>
                )}
                {tier?.price ? (
                  <div
                    onClick={() => setShowSignupModal(true)}
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                        : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                  >
                    {index === 0 ? "Try it for Free" : "Choose Plan"}
                  </div>
                ) : (
                  <div
                    onClick={handleClick}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                        : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                  >
                    Contact us
                  </div>
                )}
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-indigo-600"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="flex  justify-center mt-12 w-full">
            <div
              to={"articles"}
              className="text-lg font-semibold text-blue-600 border-b-2 border-blue-600 pb-1"
            >
              1 min Audio = 1 Credit, 1 min Video = 2 Credits
            </div>
          </div>
        </div>
      </Container>
      {showSignupModal && (
        <Signup
          setShowLoginModal={setShowSignupModal}
          onSignUpSuccess={() => {}}
        />
      )}
    </div>
  );
}
