import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container } from "./Container";
import {
  Menu,
  X,
  User,
  LogOut,
  Crown,
  TicketPercentIcon,
  CircleHelp,
} from "lucide-react";
import Login from "./Login";
import AppContext from "../AppContext";
import { EchoLogo } from "./EchoLogo";
import Signup from "./SignUp";
import { convertSecondsToMinutes } from "../constants";
import BugReportModal from "./BugReportModal";

const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <a
      href={to}
      onClick={onClick}
      className={`px-4 py-2 text-base font-medium ${
        isActive
          ? "text-blue-600 border-b-2 border-blue-600"
          : "text-slate-700 hover:text-blue-600"
      }`}
    >
      {children}
    </a>
  );
};

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const userProfilePicture = authCtx.profile?.imageUrl;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportMenuOpen, setIsReportMenuOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setIsReportMenuOpen(false); // Close the menu when opening the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMenu = () => {
    setIsReportMenuOpen((prev) => !prev);
  };
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true, state: {} });
    }

    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location, navigate]);

  const handleLogout = () => {
    authCtx.logout();
    setIsProfileMenuOpen(false);
    setIsMenuOpen(false);
  };

  const handleNavLinkClick = (e, to) => {
    e.preventDefault();
    if (location.pathname === "/") {
      const element = document.getElementById(to.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { state: { scrollTo: to } });
    }
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isSticky ? "bg-white/80 backdrop-blur-md shadow-md" : ""
      }`}
    >
      <Container className="relative flex items-center justify-between py-4">
        <div className="flex items-center">
          <EchoLogo className="h-8 w-auto sm:h-12" />
        </div>

        <div className="flex items-center lg:hidden">
          {isLoggedIn && (
            <button
              className="mr-4 inline-flex items-center rounded-full bg-yellow-400 px-3 py-1 text-sm font-semibold text-gray-800 hover:bg-yellow-300 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400 active:text-gray-800/70 transition-colors duration-200"
              onClick={() => {
                /* Handle upgrade click */
              }}
            >
              <Crown className="h-4 w-4 mr-1 text-yellow-600" />
              <span className="hidden sm:inline">Upgrade</span>
            </button>
          )}
          <button className="p-2" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (
              <X className="h-6 w-6 text-slate-700" />
            ) : (
              <Menu className="h-6 w-6 text-slate-700" />
            )}
          </button>
        </div>

        <nav
          className={`${
            isMenuOpen ? "block bg-white" : "hidden"
          } absolute left-0 right-0 top-full  shadow-md lg:relative lg:top-auto lg:shadow-none lg:flex lg:items-center`}
        >
          <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-4 p-4 lg:p-0">
            <NavLink
              to="#models"
              onClick={(e) => handleNavLinkClick(e, "#models")}
            >
              Home
            </NavLink>
            <NavLink
              to="#articles"
              onClick={(e) => handleNavLinkClick(e, "#articles")}
            >
              Articles
            </NavLink>
            <NavLink
              to="#pricing"
              onClick={(e) => handleNavLinkClick(e, "#pricing")}
            >
              Pricing
            </NavLink>
            <NavLink
              to="#contact"
              onClick={(e) => handleNavLinkClick(e, "#contact")}
            >
              Contact Us
            </NavLink>

            {isLoggedIn ? (
              <div className="lg:hidden w-full space-y-2">
                <button
                  className="w-full inline-flex items-center justify-center rounded-full bg-yellow-400 px-4 py-2 text-base font-semibold text-gray-800 hover:bg-yellow-300 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400 active:text-gray-800/70 transition-colors duration-200"
                  onClick={() => {
                    /* Handle upgrade click */
                  }}
                >
                  <Crown className="h-5 w-5 mr-2 text-yellow-600" />
                  Upgrade to Premium
                </button>
                {authCtx.profile.isAdmin && (
                  <Link
                    to="/admin-panel"
                    className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin Panel
                  </Link>
                )}
                <Link
                  to="/profile"
                  className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                >
                  <LogOut className="mr-2 h-5 w-5" />
                  Logout
                </button>
              </div>
            ) : (
              <div className="lg:hidden w-full space-y-2">
                <button
                  className="w-full inline-flex justify-center rounded-full bg-blue-600 px-4 py-2 text-base font-semibold text-white hover:bg-blue-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 active:text-white/70"
                  onClick={() => {
                    setShowSignupModal(true);
                    setIsMenuOpen(false);
                  }}
                >
                  Sign up for free
                </button>
                <button
                  className="w-full inline-flex justify-center rounded-full text-blue-600 px-4 py-2 text-base font-semibold hover:text-blue-500 focus:outline-none outline-blue-600 outline outline-[2px] focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 active:text-white/70"
                  onClick={() => {
                    setShowLoginModal(true);
                    setIsMenuOpen(false);
                  }}
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </nav>

        <div className="hidden lg:flex items-center space-x-4">
          {isLoggedIn ? (
            <>
              <button
                title={`${convertSecondsToMinutes(
                  authCtx?.credits
                )} Credits Remaining`}
                className="inline-flex items-center from-purple-400 to-indigo-500 bg-gradient-to-tr text-white rounded-full px-4 py-2 text-base font-semibold hover:from-purple-500 hover:to-indigo-600 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-500 transition-colors duration-200"
              >
                <TicketPercentIcon className="h-5 w-5 mr-2 text-white" />
                {convertSecondsToMinutes(authCtx?.credits)}
              </button>
              <button
                className="inline-flex items-center rounded-full bg-yellow-400 px-4 py-2 text-base font-semibold text-gray-800 hover:bg-yellow-300 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400 active:text-gray-800/70 transition-colors duration-200"
                onClick={() => {}}
              >
                <Crown className="h-5 w-5 mr-2 text-yellow-600" />
                Upgrade to Premium
              </button>
              {/* <div className="relative z-50 mr-2 sm:mr-4">
                <CircleHelp
                  onClick={toggleMenu}
                  className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600 cursor-pointer hover:text-blue-800 transition-colors"
                  aria-label="Help Menu"
                />
                {isReportMenuOpen && (
                  <div className="absolute z-50 w-36 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                    <button
                      onClick={openModal}
                      className="block px-4 py-2 text-blue-600 hover:bg-gray-100 w-full text-left"
                    >
                      Report a Bug
                    </button>
                  </div>
                )}
              </div> */}
              <div className="relative">
                <button
                  className="inline-flex items-center rounded-full bg-blue-600 px-1 py-1 text-base font-semibold text-white hover:bg-blue-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 active:text-white/70"
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                >
                  {userProfilePicture ? (
                    <img
                      src={userProfilePicture}
                      alt="User Profile"
                      className="h-8 w-8 rounded-full"
                    />
                  ) : (
                    <User className="h-8 w-8 p-1" />
                  )}
                </button>
                {isProfileMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {authCtx.profile.isAdmin && (
                      <Link
                        to="/admin-panel"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsProfileMenuOpen(false)}
                      >
                        Admin Panel
                      </Link>
                    )}
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setIsProfileMenuOpen(false)}
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <div className="flex items-center">
                        <LogOut className="mr-2 h-5 w-5" />
                        Logout
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <button
                className="inline-flex justify-center rounded-full bg-blue-600 px-4 py-2 text-base font-semibold text-white hover:bg-blue-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 active:text-white/70"
                onClick={() => setShowSignupModal(true)}
              >
                Sign up for free
              </button>
              <button
                className="inline-flex justify-center rounded-full text-blue-600 px-4 py-2 text-base font-semibold hover:text-blue-500 focus:outline-none outline-blue-600 outline outline-[2px] focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 active:text-white/70"
                onClick={() => setShowLoginModal(true)}
              >
                Login
              </button>
            </>
          )}
        </div>
      </Container>
      {isModalOpen && <BugReportModal onClose={closeModal} />}
      {showLoginModal && (
        <Login
          setShowLoginModal={setShowLoginModal}
          onLoginSuccess={() => {}}
        />
      )}
      {showSignupModal && (
        <Signup
          setShowLoginModal={setShowSignupModal}
          onSignUpSuccess={() => {}}
        />
      )}
    </header>
  );
}
