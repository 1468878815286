import { useState, useCallback } from "react";

const useClipboard = (duration = 2000) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback(
    (text) => {
      if (typeof text === "string" || typeof text === "number") {
        navigator.clipboard.writeText(String(text)).then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), duration);
        });
      } else {
        console.error("Cannot copy this type of data");
      }
    },
    [duration]
  );

  return { isCopied, copyToClipboard };
};

export default useClipboard;
