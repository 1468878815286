import React, { useState } from "react";

function EmailForm({ handleModalClick, showModel }) {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div
      onClick={handleModalClick}
      className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all opacity-100 scale-100"
      id="headlessui-dialog-panel"
    >
      <button
        onClick={() => showModel(false)}
        className="absolute right-3 top-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-black"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
      <h1
        className="text-2xl font-bold leading-6 text-gray-900 mb-5"
        id="headlessui-dialog-title"
      >
        Continue with email
      </h1>
      <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
        <label className="text-xs text-black" htmlFor="email">
          Enter your email address
        </label>
        <input
          className="w-full p-2 bg-white border border-black text-black outline-none rounded-md"
          placeholder="john@acme.com"
          autoComplete="off"
          type="text"
          value={email}
          name="email"
          onChange={handleInputChange}
        />
        <p className="text-xs text-gray-500 mt-3">
          We'll email you with a link for a password-free registration.
        </p>
        <button
          type="submit"
          className="bg-[#292929] mx-auto text-white block transition duration-300 ease-in-out px-4 py-2 rounded-lg font-medium cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!email}
        >
          Continue
        </button>
      </form>
    </div>
  );
}

export default EmailForm;
