export function EchoLogo(props) {
  return (
    <div className="flex gap-3">
      <img
        src="./images/logos/star.png"
        className="w-8 lg:w-10"
        alt="gd-rms_logo"
      />
      <div className="font-display text-2xl font-bold tracking-tighter text-blue-600 sm:text-4xl">
        Yasmin AI
      </div>
    </div>
  );
}
