import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Bug,
  Calendar,
  User,
  ChevronDown,
  ChevronUp,
  Search,
} from "lucide-react";
import { BASE_URL } from "../constants";
import { ErrorState, LoadingState } from "./StateComponents";
import { Container } from "./Container";
import { BackgroundImage } from "./BackgroundImage";

const BugReportsPage = () => {
  const [bugReports, setBugReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchBugReports = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/BugReport`);
        setBugReports(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch bug reports");
        setLoading(false);
      }
    };
    fetchBugReports();
  }, []);

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedReports = [...bugReports].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const filteredReports = sortedReports.filter(
    (report) =>
      report.section.toLowerCase().includes(searchTerm.toLowerCase()) ||
      report.description.toLowerCase().includes(searchTerm.toLowerCase())
  );
  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 ">
      <BackgroundImage position="right" className="-bottom-32 -top-40" />
      <Container className="relative">
        <div className="container mx-auto max-w-6xl px-4">
          <h1 className="text-5xl font-bold pb-12 text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600 text-left">
            Bug Reports
          </h1>

          <div className="bg-white bg-opacity-70 rounded-2xl shadow-xl overflow-hidden mb-8">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search bugs..."
                    className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search
                    className="absolute left-3 top-2.5 text-gray-400"
                    size={20}
                  />
                </div>
                <p className="text-gray-600">
                  Total Reports: {filteredReports.length}
                </p>
              </div>

              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-100">
                      <th
                        className="p-2 text-left cursor-pointer"
                        onClick={() => handleSort("section")}
                      >
                        <div className="flex items-center">
                          Section
                          {sortField === "section" &&
                            (sortDirection === "asc" ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            ))}
                        </div>
                      </th>
                      <th className="p-2 text-left">Description</th>
                      <th
                        className="p-2 text-left cursor-pointer"
                        onClick={() => handleSort("createdAt")}
                      >
                        <div className="flex items-center">
                          Created At
                          {sortField === "createdAt" &&
                            (sortDirection === "asc" ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            ))}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredReports.map((report) => (
                      <tr
                        key={report.id}
                        className="border-b hover:bg-gray-50 transition-colors duration-200"
                      >
                        <td className="p-2">
                          <div className="flex items-center space-x-2">
                            <Bug className="text-indigo-500" size={20} />
                            <span>{report.section}</span>
                          </div>
                        </td>
                        <td className="p-2">{report.description}</td>
                        <td className="p-2">
                          <div className="flex items-center space-x-2">
                            <Calendar className="text-gray-400" size={16} />
                            <span>
                              {new Date(report.createdAt).toLocaleString()}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BugReportsPage;
