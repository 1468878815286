import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Clock, Cpu, Edit2, Eye, Shield, ShieldOff } from "lucide-react";
import { BASE_URL } from "../constants";
import { ErrorState, LoadingState } from "./StateComponents";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
    fetchCreditUsage();
  }, []);

  const fetchCreditUsage = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/CreditConsumption/credits-by-model`
      );
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch credit consumption");
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/User/GetAll`);
      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch users");
      setLoading(false);
    }
  };

  const handleEdit = (userId) => {
    navigate(`/user/${userId}`);
  };

  const handleViewDetails = (userId) => {
    navigate(`/user/${userId}`);
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <div className="container mx-auto max-w-6xl px-4 pb-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Users</h1>

      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Email
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Role
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Status
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Plan Tier
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {user.name}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {user.email}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="flex items-center">
                    {user.isAdmin ? (
                      <div
                        className="flex items-center text-blue-600"
                        title="Administrator"
                      >
                        <Shield size={16} className="mr-1" />
                        <span className="text-sm font-medium">Admin</span>
                      </div>
                    ) : (
                      <div
                        className="flex items-center text-gray-600"
                        title="Regular User"
                      >
                        <ShieldOff size={16} className="mr-1" />
                        <span className="text-sm">User</span>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <span
                    className={`relative inline-block px-3 py-1 font-semibold ${
                      user.active ? "text-green-900" : "text-red-900"
                    } leading-tight`}
                  >
                    <span
                      aria-hidden
                      className={`absolute inset-0 ${
                        user.active ? "bg-green-200" : "bg-red-200"
                      } opacity-50 rounded-full`}
                    ></span>
                    <span className="relative">
                      {user.active ? "Active" : "Inactive"}
                    </span>
                  </span>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap capitalize">
                    {user.planTier}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <button
                    onClick={() => handleEdit(user.id)}
                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                    title="Edit User"
                  >
                    <Edit2 size={18} />
                  </button>
                  <button
                    onClick={() => handleViewDetails(user.id)}
                    className="text-green-600 hover:text-green-900"
                    title="View Details"
                  >
                    <Eye size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
