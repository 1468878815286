import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Fixed the import for jwtDecode
import { BASE_FUN_URL, BASE_URL } from "./constants";
import * as signalR from "@microsoft/signalr";
import { toast } from "react-toastify";

const AppContext = React.createContext({
  profile: null,
  isLoading: false,
  isLoggedIn: false,
  login: (profile) => {},
  updateCredit: (credit) => {},
  logout: () => {},
  setCompany: () => {},
  company: null,
  transcriptionStatus: null,
  isProcessing: false,
});

export const AppContextProvider = (props) => {
  const [profile, setProfile] = useState(() => {
    const profileString = localStorage.getItem("user_profile");
    return profileString ? JSON.parse(profileString) : null;
  });
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transcriptionStatus, setTranscriptionStatus] = useState();
  const getUser = async (email) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL}/api/Auth/SingleSignOn?email=${email}`
      );
      if (res.data.active) {
        loginHandler(res.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  const checkToken = async () => {
    try {
      let token = gapi.auth?.getToken();
      let userProfile = getCookie("userProfile");
      if (!profile?.token && token && token.id_token && token.access_token) {
        const accessToken = token.id_token;
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken.email) {
          getUser(decodedToken.email);
        }
      } else if (!profile?.token && userProfile) {
        const decodedToken = jwtDecode(userProfile);
        if (decodedToken.email) {
          await getUser(decodedToken.email);
        }
      }
    } catch (error) {
      console.error("Error checking token:", error);
    }
  };

  useEffect(() => {
    checkToken();
    getProcessingCount();
  }, []);
  useEffect(() => {
    // Create a SignalR connection
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${BASE_FUN_URL}/api`)
      .build();

    connection.on(
      "FileStatus",
      (status, timestamp, fileId, fileType, transcription) => {
        setTranscriptionStatus({
          status,
          timestamp,
          fileId,
          transcription,
          fileType,
        });
      }
    );

    connection
      .start()
      .then(() => {
        console.log("SignalR Connected");
      })
      .catch((err) => console.error("SignalR Connection Error: ", err));

    return () => {
      connection.stop();
    };
  }, []);
  const getProcessingCount = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Lookup/file-log/processingCount/${authCtx.profile.email}`
      );
      if (response.data >= 1) {
        setIsProcessing(true);
      }
    } catch {
      console.log("And error ocurred");
    }
  };
  const loginHandler = (userProfile) => {
    setProfile(userProfile);
    localStorage.setItem("user_profile", JSON.stringify(userProfile));
  };
  const checkCredit = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/User/CheckCredits`,
        JSON.stringify(profile.id), // This wraps the ID in double quotes
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      );

      if (response.status !== 200) {
        // Parse the error response from the server
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update credit");
      }

      // Parse the successful response
      const data = await response.data;
      // Update the profile with the remaining credits from the response
      setProfile((prevProfile) => {
        const updatedProfile = {
          ...prevProfile,
          remainingCredits: data.remainingCredits, // Use remainingCredits from the response
        };
        localStorage.setItem("user_profile", JSON.stringify(updatedProfile));
        return updatedProfile;
      });
    } catch (error) {
      // Handle errors (e.g., network issues, JSON parsing errors, or custom API errors)
      console.error("Error updating credit:", error);
    }
  };

  const logoutHandler = () => {
    setProfile(null);
    localStorage.removeItem("user_profile");
    document.cookie = "userProfile=; Max-Age=-99999999;";
    window.location.href = "/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const authCtx = {
    profile: profile,
    isLoading: loading,
    isLoggedIn: !!profile?.token,
    checkCredit: checkCredit,
    login: loginHandler,
    logout: logoutHandler,
    company: company,
    credits: profile?.remainingCredits,
    setCompany: setCompany,
    isProcessing: isProcessing,
    transcriptionStatus: transcriptionStatus,
  };

  return (
    <AppContext.Provider value={authCtx}>{props.children}</AppContext.Provider>
  );
};

export default AppContext;
