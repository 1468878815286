import { createClient } from "@sanity/client";

function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const client = createClient({
  projectId: "6h69xrx3",
  dataset: "production",
  apiVersion: getCurrentDate(),
  token:
    "skXcKnVDAOOiACSJgRUQS0qDfn9VaL8snuYUH6RXhdbCjfPMfOps7k8rPJOcfsho2au88Ivs7XpcQ00cxpOg0YH3T5aNtzCD6WeFtRsTipb3Y8Eep4rmfktEl3nGazsSdugq4dyTFmQyCS1LtnyvEUaOaxzLdbrmEwV9F3nZD12fCnWevOKn",
  useCdn: true,
});

export { client as sanityClient };
