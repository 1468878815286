import React, { useContext, useEffect, useState } from "react";
import { Hero } from "../Hero";
import { AIProducts } from "./AIProducts";
import Pricing from "./Pricing";
import Contact from "./Contact";
import AIProductShowcase from "./Usecase";
import Articles from "./Articles";
import SanityClient from "@sanity/client";
import Spinner from "../Spinner";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AppContext from "../../AppContext";

function Home() {
  const client = SanityClient({
    projectId: "6h69xrx3", // replace with your project ID
    dataset: "production", // replace with your dataset name
    useCdn: true,
  });
  const { state } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authCtx = useContext(AppContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch(`*[_type == "main"][0] {
          title,
          products {
            heading,
            subheading,
            message,
            models[] {
              name,
              description,
              icon {
                asset-> {
                  url
                }
              },
              path,
              color,
              useCases
            }
          },
          useCase {
            heading,
            subheading
          },
          article {
            heading,
            subheading
          },
          pricing {
            heading,
            subheading,
            subscriptionTier[] {
              name,
              id,
              price {
                monthly,
                annually
              },
               strikedPrice {
                monthly,
                annually
              },
              description,
              features,
              mostPopular
            }
          },
          contact {
            heading,
            subheading
          }
        }`);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const fetchLatestFileLog = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://localhost:7155/api/Lookup/file-log/latest/${authCtx.profile.email}`
  //     );

  //     if (response.data) {
  //       const { id, fileType } = response.data;

  //       if (fileType === "Audio") {
  //         navigate(`/audio-to-text/${id}`);
  //       } else if (fileType === "Video") {
  //         navigate(`/video-to-text/${id}`);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching the latest file log:", error);
  //   }
  // };
  if (loading) {
    return <Spinner />; // Show spinner while loading
  }

  return (
    <div>
      <AIProducts data={data.products} />
      {/* <Hero /> */}
      <AIProductShowcase products={data.products.models} data={data.useCase} />
      <Articles data={data.article} />
      <Pricing data={data.pricing} />
      <Contact data={data.contact} />
      {/* <AIModelSelection /> */}
    </div>
  );
}

export default Home;
