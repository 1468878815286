// components/ArticleDetail.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContentRenderer from "./ContentRenderer";
import { sanityClient } from "./lib/Sanity";

const ArticleDetail = () => {
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const result = await sanityClient.fetch(
          `
          *[_type == "article" && slug.current == $slug][0] {
            title,
            body,
            mainImage {
              asset -> {
                _id,
                url
              }
            },
            publishedAt,
            author -> {
              name
            },
            categories[] -> {
              title
            }
          }
        `,
          { slug }
        );
        setArticle(result);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching article:", err);
        setError(err);
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (isLoading) return <div className="text-center">Loading article...</div>;
  if (error)
    return (
      <div className="text-center text-red-500">Error loading article</div>
    );
  if (!article) return <div className="text-center">Article not found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <article className="max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold mb-4">{article.title}</h1>
        <div className="mb-6">
          <p className="text-gray-600">
            By {article.author.name} on{" "}
            {new Date(article.publishedAt).toLocaleDateString()}
          </p>
          <div className="flex flex-wrap mt-2">
            {article.categories &&
              article.categories.map((category, index) => (
                <span
                  key={index}
                  className="bg-gray-200 text-gray-700 text-sm px-3 py-1 rounded-full mr-2 mb-2"
                >
                  {category.title}
                </span>
              ))}
          </div>
        </div>
        {article.mainImage && (
          <img
            src={article.mainImage.asset.url}
            alt={article.title}
            className="w-full h-auto rounded-lg mb-8"
          />
        )}
        <div className="prose prose-lg max-w-none">
          <ContentRenderer content={article.body} />
        </div>
      </article>
    </div>
  );
};

export default ArticleDetail;
