import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Key,
  Plus,
  Minus,
  User,
  Mail,
  Calendar,
  CreditCard,
  Shield,
  ShieldOff,
} from "lucide-react";
import { Modal, Switch, Button, Input, InputNumber, message } from "antd";
import { BASE_URL, convertSecondsToMinutes } from "../constants";
import { ErrorState, LoadingState, NotFoundState } from "./StateComponents";

const UserManagementPanel = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAdminConfirm, setShowAdminConfirm] = useState(false);
  const [showCreditsDialog, setShowCreditsDialog] = useState(false);
  const [creditAmount, setCreditAmount] = useState(600);
  const [isAddingCredits, setIsAddingCredits] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    fetchUserDetails();
  }, [id]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/User/GetById?userId=${id}`);
      setUser(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch user details");
      setLoading(false);
    }
  };

  const handleToggleUser = async () => {
    try {
      await axios.put(`${BASE_URL}/User/ToggleActive/${id}`);
      setUser({ ...user, active: !user.active });
      messageApi.success(
        `User ${user.active ? "deactivated" : "activated"} successfully`
      );
    } catch (err) {
      messageApi.error("Failed to toggle user status");
    }
  };

  const handleToggleAdmin = async () => {
    try {
      await axios.post(`${BASE_URL}/User/appoint-admin?userId=${id}`);
      setUser({ ...user, isAdmin: !user.isAdmin });
      setShowAdminConfirm(false);
      messageApi.success(
        `Admin status ${user.isAdmin ? "removed" : "granted"} successfully`
      );
    } catch (err) {
      messageApi.error("Failed to update admin status");
    }
  };

  const handleCredits = async () => {
    try {
      await axios.post(`${BASE_URL}/User/UpdateRemainingMinutes`, {
        userId: id,
        credit: creditAmount * 60,
        IsIncrease: isAddingCredits,
      });

      const updatedCredits = isAddingCredits
        ? user.remainingCredits + creditAmount * 60
        : Math.max(0, user.remainingCredits - creditAmount * 60);

      setUser({ ...user, remainingCredits: updatedCredits });
      setShowCreditsDialog(false);
      messageApi.success(
        `Credits ${isAddingCredits ? "added" : "removed"} successfully`
      );
    } catch (err) {
      messageApi.error("Failed to update credits");
    }
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!user) return <NotFoundState />;

  return (
    <div className="max-w-6xl mx-auto mb-10 px-4 sm:px-6 lg:px-8">
      {contextHolder}
      <h1 className="text-3xl font-semibold text-gray-900 mb-6">
        User Management Panel
      </h1>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            User Information
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <User size={18} className="mr-2" /> Full name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Mail size={18} className="mr-2" /> Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                {user.isAdmin ? (
                  <Shield size={18} className="mr-2 text-blue-600" />
                ) : (
                  <ShieldOff size={18} className="mr-2 text-gray-500" />
                )}
                Role
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.isAdmin ? "Administrator" : "Regular User"}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <CreditCard size={18} className="mr-2" /> Plan tier
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
                {user.planTier}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Calendar size={18} className="mr-2" /> Last credit reset
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {new Date(user.lastCreditReset).toLocaleString()}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                User Status
              </h3>
              <Switch checked={user.active} onChange={handleToggleUser} />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              User is currently {user.active ? "Active" : "Inactive"}
            </p>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Admin Status
            </h3>
            <Button
              type={user.isAdmin ? "danger" : "primary"}
              icon={
                user.isAdmin ? <ShieldOff size={18} /> : <Shield size={18} />
              }
              onClick={() => setShowAdminConfirm(true)}
            >
              {user.isAdmin ? "Remove Admin" : "Make Admin"}
            </Button>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg sm:col-span-2">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Manage Credits
            </h3>
            <div className="flex items-center justify-between">
              <p className="text-2xl font-semibold text-gray-900">
                {convertSecondsToMinutes(user.remainingCredits)} credits
              </p>
              <div className="flex space-x-3">
                <Button
                  type="primary"
                  icon={<Plus size={18} />}
                  onClick={() => {
                    setIsAddingCredits(true);
                    setShowCreditsDialog(true);
                  }}
                  className="bg-green-600"
                >
                  Add Credits
                </Button>
                <Button
                  danger
                  icon={<Minus size={18} />}
                  onClick={() => {
                    setIsAddingCredits(false);
                    setShowCreditsDialog(true);
                  }}
                >
                  Remove Credits
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Admin Confirmation Modal */}
      <Modal
        title={user?.isAdmin ? "Remove Admin Rights" : "Grant Admin Rights"}
        open={showAdminConfirm}
        onOk={handleToggleAdmin}
        onCancel={() => setShowAdminConfirm(false)}
        okText={user?.isAdmin ? "Remove Admin" : "Make Admin"}
        okButtonProps={{
          danger: user?.isAdmin,
          type: user?.isAdmin ? "primary" : "default",
        }}
      >
        <p>
          Are you sure you want to {user?.isAdmin ? "remove" : "grant"} admin
          rights for {user?.name}? This will {user?.isAdmin ? "revoke" : "give"}{" "}
          them full administrative access to the system.
        </p>
      </Modal>

      {/* Credits Modal */}
      <Modal
        title={isAddingCredits ? "Add Credits" : "Remove Credits"}
        open={showCreditsDialog}
        onOk={handleCredits}
        onCancel={() => setShowCreditsDialog(false)}
        okText={isAddingCredits ? "Add Credits" : "Remove Credits"}
        okButtonProps={{
          danger: !isAddingCredits,
          type: isAddingCredits ? "primary" : "default",
        }}
      >
        <div className="py-4">
          <p className="mb-4">
            Enter the amount of credits to{" "}
            {isAddingCredits ? "add to" : "remove from"} {user?.name}'s account.
          </p>
          <InputNumber
            value={creditAmount}
            onChange={(value) => setCreditAmount(value)}
            min={1}
            className="w-full"
          />
        </div>
      </Modal>
    </div>
  );
};

export default UserManagementPanel;
