import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useDropzone } from "react-dropzone";
import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import * as signalR from "@microsoft/signalr";
import {
  Play,
  Pause,
  Upload,
  Mic,
  StopCircle,
  CheckCircle,
  Copy,
  Share2,
  Download,
} from "lucide-react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import useClipboard from "./useClipboard";
import { toast } from "react-toastify";
import AppContext from "../AppContext";
import { useOverallProgress } from "../hooks/useOverallProgress";
import { ProgressBar } from "./ProgressBar";
import LoadingButton from "./LoadingButton";
import { BASE_FUN_URL, BASE_URL, FLASK_URL } from "../constants";
import { BlobServiceClient } from "@azure/storage-blob";
import useStatusIndicator from "./StatusIndicator";
import StepIndicator from "./StepBar";
import jsPDF from "jspdf";
import { useNavigate, useParams } from "react-router-dom";
import { useAudioUpload, useSummary, useTranscription } from "../hooks/hooks";

export default function AudioToText() {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [mediaUrl, setMediaUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const authCtx = useContext(AppContext);
  const stepRef = useRef([]);
  const mediaRef = useRef(null);
  const progressRef = useRef(null);
  const recorderRef = useRef(null);
  const {
    summary,
    setSummary,
    isLoading: isSummarizing,
    handleSummarize,
  } = useSummary();
  const {
    isLoading: isUploading,
    uploadProgress,
    fileName,
    setFileName,
    handleUpload,
  } = useAudioUpload(authCtx);
  const {
    transcription,
    setTranscription,
    triggerTranscription,
    fetchLatestFileLog,
    updateFileLog,
  } = useTranscription(authCtx);
  const {
    isCopied: isTranscriptionCopied,
    copyToClipboard: copyTranscription,
  } = useClipboard();
  const { isCopied: isSummaryCopied, copyToClipboard: copySummary } =
    useClipboard();
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("audio/")) {
      setFile(file);
      setMediaUrl(URL.createObjectURL(file));
    } else {
      toast.error("Please upload an audio file only.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "audio/*",
    multiple: false,
  });

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      const setMediaData = () => {
        console.log(media.duration);
        if (media.duration !== Infinity) setDuration(media.duration);
        setCurrentTime(media.currentTime);
        console.log(media, "m");
      };

      const setMediaTime = () => setCurrentTime(media.currentTime);

      media.addEventListener("loadeddata", setMediaData);
      media.addEventListener("timeupdate", setMediaTime);

      return () => {
        media.removeEventListener("loadeddata", setMediaData);
        media.removeEventListener("timeupdate", setMediaTime);
      };
    }
  }, [mediaUrl]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorderRef.current = recorder;

      const chunks = [];
      let startTime; // To store the start time

      // Set the start time when the recording starts
      recorder.onstart = () => {
        startTime = new Date(); // Capture the start time
        console.log("Recording started at:", startTime);
      };

      // Collect data chunks during the recording
      recorder.ondataavailable = (e) => chunks.push(e.data);

      // When recording stops, calculate duration and handle the audio
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        setRecordedAudio(blob);
        const file = new File([blob], "recorded_audio.webm", {
          type: "audio/webm",
        });
        setFile(file);

        const url = URL.createObjectURL(blob);
        setMediaUrl(url);

        // Calculate the end time and duration
        const endTime = new Date(); // Capture the end time
        const durationInMilliseconds = endTime - startTime;
        const durationInSeconds = durationInMilliseconds / 1000;
        console.log("Recording ended at:", endTime);
        console.log("Total Recording Duration:", durationInSeconds, "seconds");

        // Optional: Play the recorded audio
        const audioElement = document.createElement("audio");
        audioElement.src = url;
        document.body.appendChild(audioElement);
        // Set the duration manually based on the start and end times
        setDuration(durationInSeconds);
      };

      // Start the recording
      recorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };
  useEffect(() => {
    console.log(duration, "eff");
  }, [duration]);
  const stopRecording = () => {
    if (recorderRef.current && isRecording) {
      recorderRef.current.stop();
      setIsRecording(false);
      setMediaRecorder(null);
    }
  };

  const calculateAudioSeconds = (file) => {
    return new Promise((resolve) => {
      const audio = new Audio(URL.createObjectURL(file));
      audio.onloadedmetadata = () => {
        const durationInSeconds = Math.ceil(audio.duration);
        resolve(durationInSeconds);
      };
    });
  };
  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${BASE_FUN_URL}/api`)
      .build();
    connection.on("status", (data) => {
      console.log(data);
    });
  });
  const exportToPDF = (text) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const maxWidth = pageWidth - 2 * margin;
    const lineHeight = 5;
    const fontSize = 12;

    doc.setFontSize(fontSize);

    const lines = doc.splitTextToSize(text, maxWidth);
    let cursorY = margin;

    lines.forEach((line) => {
      if (cursorY > pageHeight - margin) {
        doc.addPage();
        cursorY = margin;
      }
      doc.text(line, margin, cursorY);
      cursorY += lineHeight;
    });

    doc.save("transcription.pdf");
  };
  const shareTranscription = (text) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Transcription",
          text,
        })
        .catch((error) => console.error("Error sharing", error));
    } else {
      alert("Sharing not supported on this device");
    }
  };

  useEffect(() => {
    if (
      authCtx.transcriptionStatus != null &&
      authCtx.transcriptionStatus.fileType === "Audio"
    ) {
      updateFileLog(authCtx.transcriptionStatus, setIsLoading, setCurrentStep);
    }
  }, [authCtx?.transcriptionStatus]);

  const handleTranscribe = async () => {
    if (!authCtx.profile) {
      toast.error("Please log in to use this feature.");
      return;
    }

    setIsLoading(true);
    triggerTranscription(fileName);
  };
  useEffect(() => {
    const getLatestFileLog = async () => {
      try {
        const latestFileLog = await fetchLatestFileLog("Audio");
        if (latestFileLog) {
          const { id, fileUrl, status: latestStatus } = latestFileLog;

          setMediaUrl(fileUrl);

          if (latestStatus === "Uploading") {
            setCurrentStep(1);
          } else if (latestStatus === "Processing") {
            setCurrentStep(2);
            setFileName(id);
            setIsLoading(true);
          } else if (latestStatus === "Completed") {
            setCurrentStep(2);
            setFileName(id);
            updateFileLog(latestFileLog, setIsLoading, setCurrentStep);
          }
        }
      } catch (error) {
        console.error("Error fetching latest file log:", error);
        // Optionally, you can show an error message to the user here
      }
    };

    getLatestFileLog();
  }, []);

  const togglePlayPause = () => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleProgressChange = (e) => {
    const media = mediaRef.current;
    if (media) {
      const newTime = (e.target.value / 100) * media.duration;
      media.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };
  const startNew = () => {
    setTranscription();
    setCurrentStep(1);
    setFileName(null);
    setSummary(null);
    setFile(null);
    setMediaUrl(null);
  };
  useEffect(() => {
    if (stepRef.current[currentStep]) {
      stepRef.current[currentStep].scrollIntoView({ behavior: "smooth" });
    }
  }, [currentStep]);
  return (
    <div className="relative flex flex-col items-center w-full">
      <StepIndicator currentStep={currentStep} />
      <div
        ref={(el) => (stepRef.current[1] = el)}
        className="flex justify-center my-3 sm:my-6 bg-white text-blue-600 rounded-xl sm:rounded-3xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl"
      >
        <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
          <div className="text-base sm:text-lg font-bold">STEP 1</div>
          <div>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold mb-2 sm:mb-4">
              VoiceScribe
            </h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-8 text-black">
              Transcribe audio to text automatically, using AI. Over +120
              languages supported.
            </p>
          </div>

          <div className="bg-blue-50 rounded-xl sm:rounded-2xl p-4 sm:p-8 shadow-lg">
            {!file && !mediaUrl ? (
              <div
                className="flex flex-col items-center justify-center h-32 sm:h-48"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isRecording && mediaRecorder ? (
                  <div className="mt-4">
                    <LiveAudioVisualizer
                      mediaRecorder={mediaRecorder}
                      width={300}
                      height={50}
                    />
                  </div>
                ) : (
                  <p className="text-base sm:text-xl text-gray-600 text-center">
                    Drag and drop your audio file here
                  </p>
                )}
              </div>
            ) : mediaUrl ? (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={48} className="text-blue-500 mr-4" />
                    ) : (
                      <Play size={48} className="text-blue-500 mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                      ref={progressRef}
                    />
                  </div>
                </div>
                <div className="flex justify-between text-sm text-gray-600 mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <audio
                  ref={mediaRef}
                  src={mediaUrl}
                  controls
                  className="w-full hidden"
                />
              </>
            ) : (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={32} className="text-blue-500 mr-2 sm:mr-4" />
                    ) : (
                      <Play size={32} className="text-blue-500 mr-2 sm:mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex justify-between text-xs sm:text-sm text-gray-600 mb-2 sm:mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <audio
                  id="rAudio"
                  src={mediaUrl}
                  controls
                  className="w-full hidden"
                />
              </>
            )}
          </div>

          <div className="flex w-full justify-end">
            {!file && !mediaUrl ? (
              <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 sm:overflow-hidden sm:rounded-full">
                <div
                  {...getRootProps()}
                  className="bg-blue-600 text-white px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-blue-700 transition duration-300 cursor-pointer flex items-center justify-center flex-1 rounded-full sm:rounded-none"
                >
                  <input {...getInputProps()} />
                  <Upload className="mr-2" size={20} />
                  Upload
                </div>
                <button
                  onClick={isRecording ? stopRecording : startRecording}
                  className="bg-red-500 text-white px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-red-600 transition duration-300 flex items-center justify-center flex-1 rounded-full sm:rounded-none sm:border-l sm:border-red-400"
                >
                  {isRecording ? (
                    <>
                      <StopCircle className="mr-2" size={20} />
                      Stop Recording
                    </>
                  ) : (
                    <>
                      <Mic className="mr-2" size={20} />
                      Record
                    </>
                  )}
                </button>
              </div>
            ) : !fileName ? (
              <div className="flex flex-col sm:flex-row w-full gap-2">
                <LoadingButton
                  onClick={() => handleUpload(file, duration, setCurrentStep)}
                  className="bg-blue-600 text-white px-6 py-3 sm:px-8 sm:py-4 w-full sm:w-3/5 rounded-full text-base sm:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)] transition duration-300"
                  disabled={isUploading}
                >
                  {isUploading ? (
                    <p>
                      Uploading{" "}
                      {uploadProgress && (
                        <span className="text-xs">
                          {uploadProgress.uploaded.toFixed(2)} MB /{" "}
                          {uploadProgress.total.toFixed(2)} MB
                        </span>
                      )}
                    </p>
                  ) : (
                    "Upload"
                  )}
                </LoadingButton>
                {
                  <div
                    onClick={startNew}
                    className="bg-gray-600 text-white rounded-full w-full sm:w-2/5 px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                  >
                    Go Back
                  </div>
                }
              </div>
            ) : (
              <div className="text-xs sm:text-sm text-white font-semibold flex items-center">
                {uploadProgress && (
                  <span>
                    {uploadProgress.total.toFixed(2)}&nbsp; MB uploaded
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {currentStep >= 2 && (
        <div
          ref={(el) => (stepRef.current[2] = el)}
          className="flex justify-center  my-3 sm:my-6 bg-white text-blue-500 rounded-xl sm:rounded-3xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl"
        >
          <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
            <div className="text-base sm:text-lg font-bold">STEP 2</div>
            <div>
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold mb-2 lg:mb-4 lg:text-left">
                Transcription
              </h2>
              {/* <p className="text-lg mb-8">
                  Transcribe audio to text automatically, using AI. Over +120
                  languages supported.
                </p> */}
            </div>

            <div className="bg-blue-50 rounded-2xl p-4 md:p-6 lg:p-8 shadow-lg">
              <>
                <div className="bg-white rounded-2xl p-4 md:p-6 shadow-sm">
                  <p className="text-lg md:text-xl lg:text-2xl text-gray-800 max-h-48 lg:max-h-56 overflow-y-auto">
                    {transcription || "Transcription will appear here..."}
                  </p>
                </div>
                {transcription && (
                  <div className="flex justify-center lg:justify-start gap-2">
                    <button
                      onClick={() => copyTranscription(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Copy transcription"
                    >
                      {isTranscriptionCopied ? (
                        <CheckCircle size={24} className="text-green-500" />
                      ) : (
                        <Copy size={24} className="text-gray-500" />
                      )}
                    </button>

                    <button
                      onClick={() => shareTranscription(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Share transcription"
                    >
                      <Share2 size={24} className="text-gray-500" />
                    </button>

                    <button
                      onClick={() => exportToPDF(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Export to PDF"
                    >
                      <Download size={24} className="text-gray-500" />
                    </button>
                  </div>
                )}
              </>
            </div>
            <div></div>
            {!transcription ? (
              <div className="flex w-full justify-end">
                <div className="gap-2 flex w-full flex-col lg:flex-row">
                  <LoadingButton
                    onClick={handleTranscribe}
                    className="bg-blue-600 text-white px-6 py-3 lg:px-8 lg:py-4 w-full lg:w-3/5 rounded-full text-lg lg:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                    disabled={isLoading}
                  >
                    {isLoading ? "Converting" : "Convert to text"}
                  </LoadingButton>
                  {
                    <div
                      onClick={startNew}
                      className="bg-gray-600 text-white rounded-full w-full lg:w-2/5 px-6 py-3 lg:px-6 lg:py-4 text-lg lg:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                    >
                      Go back
                    </div>
                  }
                </div>
              </div>
            ) : !summary ? (
              <div className="flex w-full justify-end">
                <div className="gap-2 flex w-full flex-col lg:flex-row">
                  <button
                    onClick={() =>
                      handleSummarize(transcription, setCurrentStep)
                    }
                    disabled={isSummarizing}
                    className="bg-blue-600 text-white px-6 py-3 lg:px-8 lg:py-4 w-full lg:w-3/5 rounded-full text-lg lg:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                  >
                    {isSummarizing ? "Summarizing..." : "Summarize"}
                  </button>
                  <div
                    onClick={startNew}
                    className="bg-gray-600 text-white rounded-full w-full lg:w-2/5 px-6 py-3 lg:px-6 lg:py-4 text-lg lg:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                  >
                    Start new
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {summary && (
        <div
          ref={(el) => (stepRef.current[3] = el)}
          className="flex justify-center  my-3 sm:my-6 bg-white text-blue-500 rounded-xl sm:rounded-3xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl"
        >
          <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
            <div className="text-lg font-bold">STEP 3</div>
            <h2 className="text-4xl font-extrabold mb-4">Summary</h2>

            <div className="bg-blue-50 rounded-lg p-6 shadow-sm">
              <p className="text-xl bg-white rounded-2xl p-8 shadow-lg text-gray-800">
                {summary}
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => copySummary(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Copy summary"
                >
                  {isSummaryCopied ? (
                    <CheckCircle size={24} className="text-green-500" />
                  ) : (
                    <Copy size={24} className="text-gray-500" />
                  )}
                </button>

                <button
                  onClick={() => shareTranscription(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Share summary"
                >
                  <Share2 size={24} className="text-gray-500" />
                </button>

                <button
                  onClick={() => exportToPDF(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Export to PDF"
                >
                  <Download size={24} className="text-gray-500" />
                </button>
              </div>
            </div>
            <div className="flex w-full justify-end mt-3">
              <div className="gap-2 flex w-full">
                <button
                  onClick={startNew}
                  className="bg-blue-600 text-white px-8 py-4 w-3/5 rounded-full text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                >
                  Start new
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
