import * as React from "react";
import { useState } from "react";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

export default function App() {
  const [blob, setBlob] = useState();
  const recorder = useAudioRecorder();

  // Create a URL for the recorded blob
  const audioUrl = blob ? URL.createObjectURL(blob) : "";
  const audio = new Audio(audioUrl);
  console.log(audio.duration, "duration");
  return (
    <div>
      <AudioRecorder
        onRecordingComplete={setBlob}
        recorderControls={recorder}
      />

      {recorder.mediaRecorder && (
        <LiveAudioVisualizer
          mediaRecorder={recorder.mediaRecorder}
          width={200}
          height={75}
        />
      )}

      {blob && (
        <AudioVisualizer
          blob={blob}
          width={500}
          height={75}
          barWidth={1}
          gap={0}
          barColor={"#f76565"}
        />
      )}

      {blob && (
        <AudioVisualizer
          blob={blob}
          width={500}
          height={75}
          barWidth={3}
          gap={2}
          barColor={"lightblue"}
        />
      )}

      {blob && (
        <div>
          <audio controls src={audioUrl} />
        </div>
      )}
    </div>
  );
}
