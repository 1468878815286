import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constants";
import Login from "./Login";

const UserActivate = () => {
  const [activationStatus, setActivationStatus] = useState("loading");
  const [countdown, setCountdown] = useState(4);
  const [showLogin, setShowLoginModal] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const encryptedData = token;

  useEffect(() => {
    const activateUser = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/User/api/activate?encryptedData=${encodeURIComponent(
            encryptedData
          )}`
        );
        if (response.status === 200) {
          setActivationStatus("success");
          startRedirectCountdown();
        }
      } catch (error) {
        console.error(error);
        setActivationStatus("error");
      }
    };

    if (encryptedData) {
      activateUser();
    }
  }, [encryptedData]);

  const startRedirectCountdown = () => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(countdownInterval);
          //   navigate("/home/welcome"); // Replace with your desired redirect path
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const onLoginSuccess = () => {
    setShowLoginModal(false);
    setCountdown(5);
    navigate("/"); 
  };

  const renderContent = () => {
    switch (activationStatus) {
      case "loading":
        return (
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="relative w-24 h-24">
              <div className="absolute inset-0 border-t-4 border-blue-500 rounded-full animate-spin"></div>
              <div className="absolute inset-2 border-t-4 border-purple-500 rounded-full animate-spin-slow"></div>
              <div className="absolute inset-4 border-t-4 border-pink-500 rounded-full animate-spin-slower"></div>
            </div>
            <h2 className="text-2xl font-bold text-gray-800 animate-pulse">
              Activating your account...
            </h2>
          </div>
        );
      case "success":
        return (
          <div className="bg-green-100 border-l-4 border-green-500 p-4 rounded-md animate-fade-in">
            <h3 className="text-green-800 font-bold">Success!</h3>
            <p className="text-green-700">
              Your account has been successfully activated. You can now log in
              and start using our services.
            </p>
            <p className="text-green-700 font-semibold">
              For your convenience, we'll automatically open login popup in{" "}
              {countdown} seconds.
            </p>
          </div>
        );
      case "error":
        return (
          <div className="bg-red-100 border-l-4 border-red-500 p-4 rounded-md animate-fade-in">
            <h3 className="text-red-800 font-bold">Activation Failed</h3>
            <p className="text-red-700">
              We encountered an error while activating your account. Please try
              again or contact support.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-[70vh] flex items-center justify-center bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
      <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
        {renderContent()}
      </div>
      {activationStatus === "success" && countdown === 0 && (
        <Login
          setShowLoginModal={setShowLoginModal}
          onLoginSuccess={onLoginSuccess}
        />
      )}
    </div>
  );
};

export default UserActivate;
