import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import { sanityClient } from "./lib/Sanity";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ContentRenderer = ({ content }) => {
  const renderMark = (text, mark) => {
    switch (mark) {
      case "strong":
        return <strong key={text}>{text}</strong>;
      case "em":
        return <em key={text}>{text}</em>;
      default:
        return text;
    }
  };

  const renderSpan = (span, markDefs) => {
    if (span.marks.length > 0) {
      return span.marks.reduce((text, mark) => {
        if (markDefs && markDefs.length > 0) {
          const linkDef = markDefs.find((def) => def._key === mark);
          if (linkDef) {
            return (
              <a
                href={linkDef.href}
                key={span._key}
                className="text-blue-500 underline"
              >
                {text}
              </a>
            );
          }
        }
        return renderMark(text, mark);
      }, span.text);
    }
    return span.text;
  };

  const renderBlockContent = (block) => {
    switch (block._type) {
      case "block":
        switch (block.style) {
          case "h1":
            return (
              <h1 key={block._key} className="text-3xl font-bold my-4">
                {block.children.map((child) =>
                  renderSpan(child, block.markDefs)
                )}
              </h1>
            );
          case "h2":
            return (
              <h2 key={block._key} className="text-2xl font-bold my-4">
                {block.children.map((child) =>
                  renderSpan(child, block.markDefs)
                )}
              </h2>
            );
          case "h3":
            return (
              <h3 key={block._key} className="text-xl font-bold my-4">
                {block.children.map((child) =>
                  renderSpan(child, block.markDefs)
                )}
              </h3>
            );
          default:
            if (block.listItem) {
              return (
                <li key={block._key} className="my-2 text-lg">
                  {block.children.map((child) =>
                    renderSpan(child, block.markDefs)
                  )}
                </li>
              );
            } else {
              return (
                <p key={block._key} className="my-4 text-lg">
                  {block.children.map((child) =>
                    renderSpan(child, block.markDefs)
                  )}
                </p>
              );
            }
        }
      case "image":
        return (
          <figure key={block._key} className="my-4">
            <img
              src={urlFor(block.asset).url()}
              alt={block.alt || "Image"}
              layout="responsive"
              width={600}
              height={400}
              className="object-cover"
              unoptimized={true}
              quality={100}
              priority={true}
            />
            {block.caption && (
              <figcaption className="text-sm italic text-gray-600 mt-2">
                {block.caption}
              </figcaption>
            )}
          </figure>
        );
      default:
        return null;
    }
  };

  const renderList = (listItems, type) => {
    const ListTag = type === "bullet" ? "ul" : "ol";
    return (
      <ListTag
        className={
          type === "bullet" ? "list-disc pl-5 my-4" : "list-decimal pl-5 my-4"
        }
      >
        {listItems.map((item) => renderBlockContent(item))}
      </ListTag>
    );
  };

  let listItems = [];
  let listType = null;

  return (
    <div className="content-renderer">
      {content?.map((block, index) => {
        if (block._type === "block" && block.listItem) {
          if (listType === null) {
            listType = block.listItem;
          }

          if (listType === block.listItem) {
            listItems.push(block);

            const isLastListItem =
              index === content.length - 1 ||
              content[index + 1]._type !== "block" ||
              !content[index + 1].listItem;

            if (isLastListItem) {
              const renderedList = renderList(listItems, listType);
              listItems = [];
              listType = null;
              return renderedList;
            } else {
              return null;
            }
          } else {
            const renderedList = renderList(listItems, listType);
            listItems = [block];
            listType = block.listItem;
            return renderedList;
          }
        } else {
          if (listItems.length > 0) {
            const renderedList = renderList(listItems, listType);
            listItems = [];
            listType = null;
            return (
              <>
                {renderedList}
                {renderBlockContent(block)}
              </>
            );
          } else {
            return renderBlockContent(block);
          }
        }
      })}
    </div>
  );
};

export default ContentRenderer;
