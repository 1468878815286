import React, { useState, useEffect } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import {
  Mic,
  Video,
  Image as ImageIcon,
  Volume2,
  CheckCircle,
} from "lucide-react";
import { Container } from "../Container";
import { Link } from "react-router-dom";

const aiProducts = [
  {
    name: "VoiceScribe",
    description: "Convert audio to text with high accuracy",
    icon: Mic,
    path: "/audio-to-text",
    color: "from-purple-400 to-indigo-500",
    useCases: [
      "Transcribe interviews for journalists",
      "Create subtitles for podcasts",
      "Document meeting minutes automatically",
      "Transcribe legal depositions",
      "Convert lectures to text for students",
    ],
  },
  {
    name: "VideoScribe",
    description: "Transform video content into readable text",
    icon: Video,
    path: "/video-to-text",
    color: "from-green-400 to-cyan-500",
    useCases: [
      "Generate searchable transcripts for video archives",
      "Create text summaries of video tutorials",
      "Extract key points from recorded presentations",
      "Transcribe video testimonials for marketing",
      "Provide accessibility for hearing-impaired viewers",
    ],
  },
  {
    name: "ImageGen",
    description: "Create stunning images from text descriptions",
    icon: ImageIcon,
    path: "/",
    color: "from-pink-400 to-rose-500",
    useCases: [
      "Generate custom illustrations for articles",
      "Create unique social media visuals",
      "Design concept art for products",
      "Produce book cover images",
      "Generate storyboard frames for video production",
    ],
  },
  {
    name: "VoiceSynth",
    description: "Convert text to natural-sounding audio",
    icon: Volume2,
    path: "/",
    color: "from-yellow-400 to-orange-500",
    useCases: [
      "Create voiceovers for videos",
      "Generate audio versions of articles",
      "Produce automated customer service messages",
      "Create accessible audio content for visually impaired users",
      "Generate voice acting for video games",
    ],
  },
];

const AIProductShowcase = ({ data, products }) => {
  const [tabOrientation, setTabOrientation] = useState("horizontal");

  useEffect(() => {
    const lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    const onMediaQueryChange = ({ matches }) => {
      setTabOrientation(matches ? "vertical" : "horizontal");
    };

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);
  const renderIcon = (product, isSelected) => {
    if (typeof product.icon.asset.url === "string") {
      // If it's a URL, render an img element
      return (
        <img
          src={product.icon.asset.url}
          alt={`${product.name} icon`}
          className={`mr-3 invert h-6 w-6 ${!isSelected && " opacity-70"}`}
        />
      );
    } else if (React.isValidElement(product.icon.asset.url)) {
      // If it's a React component, render it using React.createElement
      return React.createElement(product.icon.asset.url, {
        className: `mr-3 h-6 w-6 ${
          isSelected ? "text-white" : "text-blue-200"
        }`,
      });
    } else {
      return null;
    }
  };

  return (
    <section
      id="ai-products"
      aria-label="AI Product Showcase"
      className="relative overflow-hidden bg-gradient-to-r from-blue-600 to-indigo-700 pb-28 pt-20 sm:py-32"
    >
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            {data.heading}
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            {data.subheading}
          </p>
        </div>
        <TabGroup
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <TabList className="grid grid-cols-2 gap-2 sm:flex sm:flex-wrap sm:gap-x-4 sm:px  relative z-10 gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {products.map((product, index) => (
                    <div
                      key={product.name}
                      className={`group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 ${
                        selectedIndex === index
                          ? "bg-white/10 lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/5 lg:hover:bg-white/5"
                      }`}
                    >
                      <h3>
                        <Tab
                          className={`font-display text-lg ui-not-focus-visible:outline-none ${
                            selectedIndex === index
                              ? "text-white"
                              : "text-blue-100 hover:text-white"
                          }`}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          <div className="flex items-center">
                            {renderIcon(product, selectedIndex === index)}
                            {product.name}
                          </div>
                        </Tab>
                      </h3>
                      <p
                        className={`mt-2 hidden text-sm lg:block ${
                          selectedIndex === index
                            ? "text-white"
                            : "text-blue-100 group-hover:text-white"
                        }`}
                      >
                        {product.description}
                      </p>
                    </div>
                  ))}
                </TabList>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {products.map((product) => (
                  <Tab.Panel
                    key={product.name}
                    className="relative rounded-2xl bg-white p-6 shadow-xl shadow-blue-900/20 sm:p-8"
                  >
                    <h3 className="text-2xl font-bold text-gray-900">
                      {product.name}
                    </h3>
                    <p className="mt-2 text-gray-700">{product.description}</p>
                    <div className="mt-6">
                      <h4 className="text-lg font-semibold text-gray-900">
                        Top Use Cases:
                      </h4>
                      <ul className="mt-4 space-y-3">
                        {product.useCases.map((useCase, index) => (
                          <li key={index} className="flex items-start">
                            <CheckCircle className="h-6 w-6 flex-shrink-0 text-green-500" />
                            <span className="ml-3 text-gray-700">
                              {useCase}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-8 flex justify-end">
                      <Link
                        to={product.path}
                        className="rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Learn More
                      </Link>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </TabGroup>
      </Container>
    </section>
  );
};

export default AIProductShowcase;
