// components/ArticleList.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { sanityClient } from "./lib/Sanity";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const result = await sanityClient.fetch(`
          *[_type == "article"] | order(publishedAt desc) {
            _id,
            title,
            slug,
            mainImage {
              asset -> {
                _id,
                url
              }
            },
            publishedAt,
            author -> {
              name
            },
            categories[] -> {
              title
            }
          }
        `);
        setArticles(result);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching articles:", err);
        setError(err);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (isLoading) return <div className="text-center">Loading articles...</div>;
  if (error)
    return (
      <div className="text-center text-red-500">Error loading articles</div>
    );

  return (
    <div className="container mx-auto px-4 pb-12 max-w-6xl">
      <h1 className="text-3xl font-bold mb-8">Latest Articles</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
        {articles.map((article) => (
          <Link
            to={`/article/${article.slug.current}`}
            key={article._id}
            className="block"
          >
            <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
              {article.mainImage && (
                <img
                  src={article.mainImage.asset.url}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
                <p className="text-gray-600 text-sm mb-2">
                  By {article.author.name} on{" "}
                  {new Date(article.publishedAt).toLocaleDateString()}
                </p>
                <div className="flex flex-wrap">
                  {article.categories &&
                    article.categories.map((category, index) => (
                      <span
                        key={index}
                        className="bg-gray-200 text-gray-700 text-xs px-2 py-1 rounded-full mr-2 mb-2"
                      >
                        {category.title}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ArticleList;
